import '../src/css/public'

const images = require.context('../src/img', true)
const fonts = require.context('../src/fonts', true)

import jquery from "jquery";

global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

// Node modules
import 'bootstrap';
import 'waypoints/lib/jquery.waypoints';
import { Fancybox } from "@fancyapps/ui";
import 'jquery.cookie'
import Swal from 'sweetalert2';
import Swiper from '../src/js/swiper.min' 
import moment from 'moment';
import 'jquery.maskedinput/src/jquery.maskedinput';
import 'daterangepicker/daterangepicker';
import '@chenfengyuan/datepicker';
import 'slick-carousel';
import HystModal from 'hystmodal/src/hystmodal';
import intlTelInput from 'intl-tel-input';

import { I18n } from "i18n-js";
import ru from "translations/ru.json";
import en from "translations/en.json";
const i18n = new I18n();
i18n.store(en);
i18n.store(ru);
i18n.missingBehavior = "guess";

// Меню для мобильных устройств
import '../src/js/jquery.dlmenu';
import '../src/js/jquery.dlmenu.autofill';
import '../src/js/bootstrap-hover-dropdown';
import '../src/js/wp.app';
import '../src/js/navbar.multi';
import '../src/js/jquery.uitotop';
import '../src/js/device';
import '../src/js/zabuto_calendar';

global.moment = moment;
global.Swiper = Swiper;
global.i18n = i18n;
global.intlTelInput = intlTelInput;

$(document).ready(function() {
  let lang = $.cookie($('#locale_cookie_key').val());
  i18n.locale = lang;
  moment.locale(i18n.locale);


  initializeDonationForm();
  let yandex_metrika = $('#yandex_metrika').val();
  // Карусель на странице новости, статьи и мероприятия
  $(".carousel .change-photo").on("click",function(){
    let photo=$(this).attr("data-big");
    let href=$(this).attr("href");
    if(typeof photo !=="undefined" && typeof href !=="undefined")
    {
      $(this).addClass("active").parents(".carousel").find(".change-photo.active").not($(this)).removeClass("active");
      $("#big-photo").attr("href", href).children().attr("src", photo);
    }
    return false;
  });

  // Подписка на новости
  $("#new_subscription").submit(function (e) {
    e.preventDefault();
    let form = $(this);
    let url = form.attr('action');
    let formData = new FormData(this);
    if ($('#personal_data').prop('checked') === true) {
      $.ajax({
        url: url,
        type: "POST",
        dataType: "json",
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        success: function (data) {
          if (data.saved) {
            swalSuccess();
            $("#new_subscription")[0].reset();
            ToggleEnable('new_subscription');
          }
          else {
            swalErrors(data.errors);
          }
        },
        error: function (data) {
          swalInternalError();
        }
      });
      // executeRecaptchaForNewSubscription();
    }
    else {
      swalErrors([i18n.t('accept_personal_data')]);
    }
  });

  // Обратная связь
  $(".feedback-form").submit(function (e) {
    e.preventDefault();
    let form = $(this);
    let url = form.attr('action');
    let formData = new FormData(this);
    $.ajax({
      url: url,
      type: "POST",
      dataType: "json",
      data: formData,
      cache: false,
      contentType: false,
      processData: false,
      success: function (data) {
        if (data.saved) {
          swalSuccess();
          $(".feedback-form")[0].reset();
          $('#form_call').removeClass('hystmodal--active');
          $('.hystmodal__shadow').removeClass('hystmodal__shadow--show');
        }
        else {
          swalErrors(data.errors);
        }
      },
      error: function (data) {
        swalInternalError();
      }
    });
    // executeRecaptchaForFeedback();
  });

  // Заявка на волонтерство
  $("#new_volunteer").submit(function (e) {
    e.preventDefault();
    let form = $(this);
    let url = form.attr('action');
    let formData = new FormData(this);
    if ($('#personal_data').prop('checked') === true) {
      $.ajax({
        url: url,
        type: "POST",
        dataType: "json",
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        success: function (data) {
          if (data.saved) {
            swalSuccess();
            $("#new_volunteer")[0].reset();
          }
          else {
            swalErrors(data.errors);
          }
        },
        error: function (data) {
          swalInternalError();
        }
      });
      // executeRecaptchaForSaveVolunteer();
    }
    else {
      swalErrors([i18n.t('accept_personal_data')]);
    }
  });

  // Заявка на сотрудничество
  $("#new_cooperation_request").submit(function (e) {
    e.preventDefault();
    let form = $(this);
    let url = form.attr('action');
    let formData = new FormData(this);

    if ($('#personal_data').prop('checked') === true) {
      $.ajax({
        url: url,
        type: "POST",
        dataType: "json",
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        success: function (data) {
          if (data.saved) {
            swalSuccess();
            $("#new_cooperation_request")[0].reset();
          }
          else {
            swalErrors(data.errors);
          }
        },
        error: function (data) {
          swalInternalError();
        }
      });
      // executeRecaptchaForSaveCooperationRequest();
    }
    else {
      swalErrors([i18n.t('accept_personal_data')]);
    }
  });

  // Заявка на помощь
  $("#new_request").submit(function (e) {
    e.preventDefault();
    let form = $(this);
    let url = form.attr('action');
    let formData = new FormData(this);
    if ($('#personal_data').prop('checked') === true) {
      $.ajax({
        url: url,
        type: "POST",
        dataType: "json",
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        success: function (data) {
          if (data.saved) {
            swalSuccess();
            $("#new_request")[0].reset();
          }
          else {
            swalErrors(data.errors);
          }
        },
        error: function (data) {
          swalInternalError();
        }
      });
      // executeRecaptchaForSaveHelpRequest();
    }
    else {
      swalErrors([i18n.t('accept_personal_data')]);
    }
  });

  // $(".phone_mask").mask("+9(999)999-9999");

  // Навигация на мобильных устройствах
  $('#sf-nav').sfNavMulti({
    searchContainer: true,
    horizontalSubmenu: true,
    transferHeadingItem: true,
    fixedFlag: true,
    fixedId: "#sf-fixed-id",
    fixedClass: 'nav-fixed',
    fullScreenFlag: true,
    fullScreenTheme: 'theme-light full-screen-color',
    transformFlag: true,
    transformPaddingOne: 't-0 p-3',
    transformPaddingLower: '',
    transformEffectHover: 'blackout',
    transformTheme: 'theme-dark child-color-submenu fixed-theme-light-submenu fixed-submenu-color',
    mobileFlag: true
  });

  // Галерея
  Fancybox.bind("[data-fancybox]", {});
  Fancybox.bind(".theater", {});

  // Подтверждение использования кукисов
  checkCookies();

  // Слайдер изображений
  $('.slide-slick').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true
  });

  // Скрывает показывает описание галереи
  $('.link-gallery').click(function() {
    $(this).parents('.col-md-12').find('.photo-desc').toggleClass('active')
  })

  // Выравнивает по высоте блоки с разной высотой, берет большую(главная новости)
  function setEqualHeight(columns) {
    var tallestcolumn = 0;
    columns.each(
      function() {
        const currentHeight = $(this).height();
        if (currentHeight > tallestcolumn) {
          tallestcolumn = currentHeight;
        }
      }
    );
    columns.height(tallestcolumn);
  }
  setEqualHeight($(".item-slide .diagnosis"));
  setEqualHeight($(".item-slide .measures"));
  setEqualHeight($(".already-help__desc .diagnosis"));
  setEqualHeight($(".already-help__desc .anounces"));
  setEqualHeight($(".small li:first-child"));
  setEqualHeight($(".small li:nth-child(2)"));
  setEqualHeight($(".small li:nth-child(3)"));
  setEqualHeight($(".inverse h4.mt-15"));
  setEqualHeight($(".article .main-news-name"));
  setEqualHeight($(".article .main-programm-name"));
  setEqualHeight($(".article .subtitle"));
  setEqualHeight($(".article .news_subtitle"));

  // Табы в детях
  $(function() {
    $('ul.tabs-list li:first-child').addClass('active');
    $('.tab-content-item:first-child').addClass('active');
    $('ul.tabs-list').on('click', 'li:not(.active)', function() {
      $(this).addClass('active').siblings().removeClass('active').closest('div.tabs-wrap').find('div.tab-content-item').removeClass('active').eq($(this).index()).addClass('active');
    });
  });
});

// animation function
global.animationIn = function animationIn(animationId, msDelay, nameAnimation) {
  $(animationId).css('animation-delay', msDelay / 1000 + 's');
  $(animationId).addClass(nameAnimation + ' animated').one('animationend webkitAnimationEnd mozAnimationEnd MSAnimationEnd oAnimationEnd',
    function () {
      $(this).removeClass(nameAnimation + ' animated');
  });
}

// Отключает возможность повтороной подписки на новости
global.ToggleEnable = function ToggleEnable(form_id) {
  var form = document.getElementById(form_id);
  var ells = form.querySelectorAll("input,textarea");
  for (var el of ells) {
    if (el.getAttribute("disabled") == "disable") {
      el.removeAttribute("disabled");
    } else {
      el.setAttribute("disabled", "disable");
    }
  }
};

global.swalSuccess = () => {
  Swal.fire({
    title: i18n.t('message_sent'),
    type: '',
    showConfirmButton: true,
    html: `<p style="color: #000;width: 95%;">${i18n.t('we_will_contact_soon')}</p><button class="action dialog__close"><svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="16.9707" width="2" height="24" transform="rotate(45 16.9707 0)" fill="#ACACBC"/><rect y="1.41431" width="2" height="24" transform="rotate(-45 0 1.41431)" fill="#ACACBC"/></svg></button>`
  });

  $('.swal2-container .action').on('click', function() {
    Swal.close();
  });
};

global.swalInternalError = () => {
  Swal.fire({
    title: '',
    type: '',
    showConfirmButton: true,
    html: `<p style="color: #000;width: 95%;">${i18n.t('unexpected_error')}</p><button class="action dialog__close"><svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="16.9707" width="2" height="24" transform="rotate(45 16.9707 0)" fill="#ACACBC"/><rect y="1.41431" width="2" height="24" transform="rotate(-45 0 1.41431)" fill="#ACACBC"/></svg></button>`
  });

  $('.swal2-container .action').on('click', function() {
    Swal.close();
  });
};

global.swalPaymentMethodUnavailable = () => {
  Swal.fire({
    title: '',
    type: '',
    showConfirmButton: true,
    html: `<p style="color: #000;width: 95%;">${i18n.t('payment_method_unavailable')}</p><button class="action dialog__close"><svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="16.9707" width="2" height="24" transform="rotate(45 16.9707 0)" fill="#ACACBC"/><rect y="1.41431" width="2" height="24" transform="rotate(-45 0 1.41431)" fill="#ACACBC"/></svg></button>`
  });
  $('.swal2-container .action').on('click', function() {
    Swal.close();
  });
};

global.swalErrorPhone = () => {
  Swal.fire({
    title: '',
    type: '',
    showConfirmButton: true,
    html: `<p style="color: #000;width: 95%;">${i18n.t('enter_correct_phone')}</p><button class="action dialog__close"><svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="16.9707" width="2" height="24" transform="rotate(45 16.9707 0)" fill="#ACACBC"/><rect y="1.41431" width="2" height="24" transform="rotate(-45 0 1.41431)" fill="#ACACBC"/></svg></button>`
  });

  $('.swal2-container .action').on('click', function() {
    Swal.close();
  });
};

global.swalErrorAmount = () => {
  Swal.fire({
    title: '',
    type: '',
    showConfirmButton: true,
    html: `<p style="color: #000;width: 95%;">${i18n.t('enter_donation_amount')}</p><button class="action dialog__close"><svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="16.9707" width="2" height="24" transform="rotate(45 16.9707 0)" fill="#ACACBC"/><rect y="1.41431" width="2" height="24" transform="rotate(-45 0 1.41431)" fill="#ACACBC"/></svg></button>`
  });

  $('.swal2-container .action').on('click', function() {
    Swal.close();
  });
};

global.swalErrorMail = () => {
  Swal.fire({
    title: '',
    type: '',
    showConfirmButton: true,
    html: `<p style="color: #000;width: 95%;">${i18n.t('enter_correct_email')}</p><button class="action dialog__close"><svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="16.9707" width="2" height="24" transform="rotate(45 16.9707 0)" fill="#ACACBC"/><rect y="1.41431" width="2" height="24" transform="rotate(-45 0 1.41431)" fill="#ACACBC"/></svg></button>`
  });

  $('.swal2-container .action').on('click', function() {
    Swal.close();
  });
}

global.swalErrorOffer = () => {
  Swal.fire({
    title: '',
    type: '',
    showConfirmButton: true,
    html: `<p style="color: #000;width: 95%;">${i18n.t('agree_personal_data')}</p><button class="action dialog__close"><svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="16.9707" width="2" height="24" transform="rotate(45 16.9707 0)" fill="#ACACBC"/><rect y="1.41431" width="2" height="24" transform="rotate(-45 0 1.41431)" fill="#ACACBC"/></svg></button>`
  });

  $('.swal2-container .action').on('click', function() {
    Swal.close();
  });
}

global.swalErrors = (errors) => {
  let html = `<p style="color: #004c75;">${i18n.t('fix_errors')}:</p><ol>`
  $(errors).each(function() {
    html += `<li>${this}</li>`
  });
  html += '</ol><button class="action dialog__close"><svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="16.9707" width="2" height="24" transform="rotate(45 16.9707 0)" fill="#ACACBC"/><rect y="1.41431" width="2" height="24" transform="rotate(-45 0 1.41431)" fill="#ACACBC"/></svg></button></button>'
  Swal.fire({
    title: i18n.t('found_errors'),
    type: 'error',
    showConfirmButton: true,
    html: html
  });

  $('.swal2-container .action').on('click', function() {
    Swal.close();
  });
}

// Проверка использованиия Cookie
function checkCookies() {
  let use_cookies = $.cookie('use_cookies');
  let cookieNotification = document.getElementById('cookie_notification');
  let cookieBtn = cookieNotification.querySelector('.cookie_accept');
  if (!use_cookies) {
    cookieNotification.classList.add('show');
    cookieBtn.addEventListener('click', function() {
      $.cookie('use_cookies', true);
      cookieNotification.classList.remove('show');
    });
  }
}

// Копирование текста(фандрайзер)
global.copytext = function copytext(el) {
  let $tmp = $("<input>");
  $("body").append($tmp);
  $tmp.val($(el).text()).select();
  document.execCommand("copy");
  $tmp.remove();
  $('#modal_fand').removeClass('hystmodal--active');
  $('.hystmodal__shadow').removeClass('hystmodal__shadow--show');
}

// Проверка корректности ввода почты
global.emailCheck = function emailCheck (email) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};


// Расчет итоговой суммы пожертвования
global.calc_summary = function calc_summary(form) {
  $(form).find('.itog_p b').html('0');
  let count = 1;
  $(form).find('.checkbox-form-move').each(function() {
    if ($(this).is(':checked')) {
      count = count + 1;
    }
  })
  let val = $(form).find('.payment_amount').val();
  $(form).find('.itog_p b').html(parseInt(val) * count);
}

function changeCurrency(banners, currency) {
  document.querySelector('.n-p__some-much span').innerText = currency
  document.querySelector('.itog_p font').innerText = currency
  for(var i = 0; i < banners.length; i++) {
    banners[i].getElementsByTagName('span')[1].innerText = currency;
  }
  document.getElementById('final-amount').classList.remove('d-none')
}

function setCurrency(banners) {
  let active_tab = $('.n-p__tabs ul li.active');

  if (active_tab.attr('id') === 'requisites') {
    document.getElementById('final-amount').classList.add('d-none')
  } else if (active_tab.attr('id') === 'card') {
    let currency_symbol = $.cookie('currency_symbol');
    if (currency_symbol === '€') {
      $("#euro_card").click();
      changeCurrency(banners, '₽');
    } else if (currency_symbol === '₸') {
      $("#tenge_card").click();
      changeCurrency(banners, '₽');
    } else if (currency_symbol === 'C') {
      $("#som_card").click();
      changeCurrency(banners, '₽');
    } else {
      $("#dollar_card").click();
      changeCurrency(banners, '₽');
    }
  } else if (active_tab.attr('id') === 'from_ru') {
    $("#from_ru_sberbank").click();
    changeCurrency(banners, '₽');
  }
}

global.initializeDonationForm = function initializeDonationForm() {
  // Обработка формы пожертвования
  $(".donate-form").on('submit', async function (e) {
    e.preventDefault();
    // let donate_recaptcha = await executeRecaptchaForDonateAsync();
    let form = $(this);
    let url = form.attr('action');
    let formData = new FormData(this);
    let errors = false;
    let email = formData.get('donation[email]');

    if (formData.get('donation[recurrent]')) {
      swalPaymentMethodUnavailable();
      errors = true
    }

    if (formData.get('donation[amount]') == '') {
      swalErrorAmount();
      errors = true
    }

    if (formData.get('donation[payment_method]') == 'mobile') {
      if (formData.get('donation[phone]') === '') {
        swalErrorPhone();
        errors = true
      }
    }
    else {
      if (email == '' || emailCheck(email) == null) {
        swalErrorMail();
        errors = true
      }
    }

    if (form.closest('.row').find('#public_offer').length > 0 && form.closest('.row').find('#public_offer').is(":checked") == false) {
      swalErrorOffer();
      errors = true
    }

    if (!errors) {
      let tag = $.cookie('FAND');
      let fundraiser = $('#fundraiser').val();

      formData.append('donation[tag]', tag);
      formData.append('donation[fundraiser]', fundraiser);
      // formData.append('g-recaptcha-response-data[donate]', donate_recaptcha);
      $.ajax({
        url: url,
        type: "POST",
        dataType: "json",
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        success: function (data) {
          console.log('success');
          console.log(data);
          if (data.saved) {
            try {
              ym(Number(yandex_metrika.value), 'reachGoal', 'zayavka');
              VK.Goal('lead');
            } catch { }
            if (data.payment_gateway === 'uniteller') {
              $('#unitellerPay input[name=Order_IDP]').val(data.payment_id);
              $('#unitellerPay input[name=Subtotal_P]').val(data.amount);
              $('#unitellerPay input[name=Signature]').val(data.signature);
              $('#unitellerPay input[name=Email]').val(data.email);
              $('#unitellerPay input[name=Phone]').val(data.phone);
              $('#unitellerPay input[name=IsRecurrentStart]').val(data.recurrent);
              $("#unitellerPay").submit();
            } else if (data.payment_gateway === 'yookassa') {
              window.location.href = data.payment_url;
            } else if (data.payment_gateway === 'mixplat') {
              window.location.href = data.payment_url;
            } else if (data.payment_gateway === 'cloudpayments') {
              cloudPaymentPay(data.public_key, data.email, data.payment_id, data.description, data.amount, data.currency)
            } else if (data.payment_gateway === 'qiwi_gate') {
              window.location.href = data.payment_url;
            } else if (data.payment_gateway === 'paybox') {
              window.location.href = data.payment_url;
            } else {
              swalInternalError();
            }
          } else {
            swalErrors(data.errors);
          }
        },
        error: function (data) {
          console.log('error');
          console.log(data);
          swalInternalError();
        }
      });
    }
    return errors;
  });


  let banners = document.getElementsByClassName('n-p__some-numeral')
  setCurrency(banners);

  // Меняем валюту при выборе валюты во вкладке картой
  $('.n-p__tabs-content .n-p__tabs-types label').on('click', function () {
    if (this.id === 'som_card') {
      changeCurrency(banners, 'C');
    } else if (this.id === 'dollar_card') {
      changeCurrency(banners, '$');
    } else if (this.id === 'euro_card') {
      changeCurrency(banners, '€');
    } else if (this.id === 'tenge_card') {
      changeCurrency(banners, '₸');
    }
  })

  // табы видов оплаты
  $('.n-p__tabs ul li').on('click', function () {
    $(this).addClass('active')
    .siblings()
    .removeClass('active')
    .closest('div.n-p__tabs')
    .find('div.n-p__tabs-content')
    .removeClass('active').eq($(this).index()).addClass('active');

    // Меняем валюту
    setCurrency(banners);
  });

  // События появления форм
  $('.n-p__submit').on('click', function () {
    $('.n-p__final-form').toggleClass('active');
    $('.n-p__layer-sec').toggleClass('active');

    const input = document.querySelector("#donation_phone");
    if (input) {
      intlTelInput(input, {
        nationalMode: false,
        preferredCountries: ["ru"],
        utilsScript: '/js/utils.js'
      })
    }
  });

  $('.n-p__final-close').on('click', function () {
    $('.n-p__final-form').removeClass('active');
    $('.n-p__layer-sec').removeClass('active');
  });

  $('.n-p__list-more').on('click', function () {
    $('.n-p__some').toggleClass('hiddens');
  });

  // Выбор ребенка на странице пожертвования
  $('.checkbox-form-move').on('change', function () {
    let form = $(this).closest('form');
    calc_summary(form);
  });

  // Изменение суммы пожертвования
  $('.payment_amount').on('change', function () {
    let form = $(this).closest('form');
    form.find('.veof span').html($(this).val());
    calc_summary(form);
  });

  $('.payment_amount').on('keyup', function () {
    let form = $(this).closest('form');
    form.find('.veof span').html($(this).val());
    calc_summary(form);
  });

  //для нормального позиционирования абсолютом всей платилки
  $('.n-p__layer').parent('div.col-md-8').css('position', 'static');
  $('.n-p__layer').parent('div.body-wrap').css('overflow', 'none !important');
  // табы видов оплаты
  $('.n-p__tabs ul').on('click', 'li:not(.active)', function () {
    $(this)
    .addClass('active').siblings().removeClass('active')
    .closest('div.n-p__tabs').find('div.n-p__tabs-content').removeClass('active').eq($(this).index()).addClass('active');
  });
  $('.n-p__tabs ul').on('click', 'li:not(.active)', function () {
    $(this)
    .addClass('active').siblings().removeClass('active')
    .closest('div.n-p__tabs').find('div.n-p__tabs-content').removeClass('active').eq($(this).index()).addClass('active');
  });

  // $(".phone_mask").mask("+9(999)999-9999");
  $(".date_mask").mask("99.99.9999");

  // Prevents number inputs from changing value when scrolling and focusing
  $('form').on('focus', 'input[type=number]', function (e) {
    $(this).on('wheel.disableScroll', function (e) {
      e.preventDefault()
    })
  });

  $('form').on('blur', 'input[type=number]', function (e) {
    $(this).off('wheel.disableScroll')
  });
}

function cloudPaymentPay(public_key, email, payment_id, desc, amount, currency) {
  $('body').find("#donationModalContent").empty();
  let widget = new cp.CloudPayments();
  widget.pay('auth',
      {
        publicId: public_key,
        description: desc,
        amount: amount,
        currency: currency,
        accountId: email,
        invoiceId: payment_id,
        email: email,
        skin: "mini",
        data: {
          myProp: ''
        }
      },
      {
        onSuccess: function (options) {
          window.location.href = '/success-pay';
        },
        onFail: function (reason, options) {
          window.location.href = '/error_pay';
        },
        onComplete: function (paymentResult, options) {
          window.location.href = '/success-pay';
        }
      }
  )
}